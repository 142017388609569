<template>
    <div class="body mb-4" style="min-height: auto">
      <div class="container" style="padding: 2rem 2rem 0 2rem">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h1 class="text-orange fw-bold" style="font-size: 1.7rem" v-if="user.name">Where are you delivering to, {{ user.name.split(' ')[0] }}?</h1>
            </div>
            
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <status v-model:status_data=status_data></status>
                    </div>
                </div>
            </div>

            <form id="form" action="#" @submit.prevent="mapDistance()">
                <div class="col-lg-12 col-md-12 mt-4">
                    <div class="d-flex">
                        <img class="mx-auto" :src="require('../../assets/img/icons8-delivery-64.png')" alt="">
                    </div> 

                    <div class="form-row mt-3">
                        <label class="fw-bold" for="deliveryTo">Where you want it delivered</label>
                        <div class="input-group mt-2">
                            <span class="input-group-text border-right-0">
                                <font-awesome-icon id="eye" :icon="['fa', 'location-arrow']" />
                            </span>
                            <input type="text" name="deliveryTo" class="form-control border-info pt-2 pb-2" placeholder="Enter the package destination." id="deliveryTo" @change="deliveryTo()" required>
                        </div>
                        <small>You can put in your address or where you want to pick it up</small>
                    </div>

                    <div class="d-flex">
                        <button class="btn bg-orange text-white btn-sm w-100 mt-4" type="submit">
                            Create Delivery <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                        </button> 
                    </div>
                </div> 
            </form>                       
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
    .icon-circle {
        cursor: pointer;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        padding: 5px;
    } 
</style>

<script>
import Status from '../../components/Status';
import mapFunctions from '../../mixins/mapFunctions'

export default {
    name: 'ChooseStock',
    mixins: [mapFunctions],
    components: {
        Status
    },
    props: {
        user: {
            required: true,
            type: Object
        },
        screen: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            form_screen: 0,
            data: {
                delivery_from: {
                    name: 'Westlands',
                    coordinates: {
                        lat: -1.263839,
                        lng: 36.802224
                    }
                },
                delivery_to: {
                    name: null,
                    coordinates: {
                        lng: null,
                        lat: null
                    }
                },
                distance: null,
                duration: null
            },
            status_data: {
                "success": false,
                "success_message": null,
                "error": false,
                "error_message": null   
            }
        }
    },
    mounted() {
        this.initAutoComplete('deliveryTo')
    },
    methods: {
        initGeocoder(address) {
            const geocoder = new window.google.maps.Geocoder();
  
            geocoder.geocode({ address: address }, (results, status) => {
                if (status === "OK") {
                    return results[0].geometry.location
                } else {
                    return "Geocode was not successful for the following reason: " + status;  
                }
            });
        },
        deliveryTo() {
            const geocoder = new window.google.maps.Geocoder();
            let request = { 
                address: document.getElementById('deliveryTo').value 
            }
            
            geocoder.geocode(request, (results, status) => {
                if (status === "OK") {
                    this.data.delivery_to = {
                        name: results[0].formatted_address,
                        coordinates: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        }
                        
                    }
                    console.log(this.data.delivery_to)
                } else {
                    this.data.delivery_to.name = document.getElementById('deliveryTo').value 
                }
            });
        },
        changeScreen() {
            this.$emit('update:screen', this.screen+1)
        },
        routeTo() {
            this.$router.push({name: 'Delivery'})
        },
        submitDelivery() {
            let data = new FormData();

            data.append('delivery_to', this.data.delivery_to.name)
            data.append('delivery_from', this.data.delivery_from.name)
            data.append('duration', this.data.duration)
            data.append('distance', this.data.distance)
            
            this.axios.post('api/delivery', data).then((response) => {
                this.status_data.loading = false
                this.status_data.success = true
                this.status_data.error = false
                this.status_data.success_message = 'Success!'

                const delivery_data = response.data.data

                setTimeout(() => {
                    this.$router.push({name: 'DeliveryDetails', params: {delivery_data: delivery_data}})
                }, 1000);
            }).catch(errors => {
                this.status_data.loading = false
                this.status_data.success = false
                this.status_data.error = true
                this.status_data.success_message = 'Errors found!' + errors.message

                if(errors.data) {
                    this.errors = errors.data
                }

                console.log(errors);
            })
        },
        mapDistance() {
            var origin1 = new window.google.maps.LatLng(this.data.delivery_from.coordinates.lat, this.data.delivery_from.coordinates.lng);
            var origin2 = this.data.delivery_from.name;

            var destinationA = document.getElementById('deliveryTo').value;
            var destinationB = new window.google.maps.LatLng(this.data.delivery_to.coordinates.lat, this.data.delivery_to.coordinates.lng);

            var service = new window.google.maps.DistanceMatrixService();
            var request = {
                origins: [origin1, origin2],
                destinations: [destinationA, destinationB],
                travelMode: 'DRIVING',
                avoidHighways: false,
                avoidTolls: false,
            }

            service.getDistanceMatrix(request).then(response => {
                this.data.distance = response.rows[0].elements[0].distance.value
                this.data.duration = response.rows[0].elements[0].duration.value;

                this.submitDelivery()
            });
        }
    }
}
</script>