export default {
    mounted() {
        this.getUserLocation()
    },
    data() { 
        return {
            map: null,
            center: { lat: 0, lng: 0 },
            marker: null,
            polyline: null,
            here_addr: null
        }
    },
    methods: {
        getUserLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.center.lat = position.coords.latitude,
                    this.center.lng = position.coords.longitude
                    
                    this.initReverseGeocoder(this.center)
                    this.initMap(this.center, 17)
                    this.initMarkers(this.center)
                }, () => {
                    this.center.lat = -1.285957284172347
                    this.center.lng =  36.818803429723516
                });
            } else { 
                alert("Geolocation is not supported by this browser.")
            }
        },
        initMap(value, zoom) {
            this.map = new window.google.maps.Map(document.getElementById("map"), {
                center: value,
                zoom: zoom,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false
            }); 
        },   
        initGeocoder(address) {
            const geocoder = new window.google.maps.Geocoder();
  
            geocoder.geocode({ address: address }, (results, status) => {
                if (status === "OK") {
                    return results[0].geometry.location
                } else {
                    return "Geocode was not successful for the following reason: " + status;  
                }
            });
        },
        initReverseGeocoder(latlng) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    this.here_addr = results[0].formatted_address
                } else {
                    window.alert("No results found");
                }
            } else {
                window.alert("Geocoder failed due to: " + status);
            }
            });
        },
        initMarkers(value)  {
            this.marker = new window.google.maps.Marker({
                position: value,
                map: this.map,
            });
        },
        initDirections() {
            const directionsRenderer = new window.google.maps.DirectionsRenderer();
            const directionsService = new window.google.maps.DirectionsService();
  
            directionsRenderer.setMap(this.initMap(this.data.fromLocation.coordinates, 12))
            
            this.initMarkers(this.data.fromLocation.coordinates)
  
            var request = {
                origin: this.data.fromLocation.coordinates,
                destination: this.data.toLocation.coordinates,
                travelMode: 'DRIVING'
            };
  
            directionsService.route(request, (response, status) => {
                if (status === "OK") {
                    directionsRenderer.setDirections(response);
                } else {
                    window.alert("Directions request failed due to " + status);
                }
            }
            ); 
        },
        initAutoComplete(value) {
            new window.google.maps.places.Autocomplete(document.getElementById(value), {
                componentRestrictions: {'country': ['KE']}
            })
        },
        initPolylines() {
            const map = new window.google.maps.Map(document.getElementById("map"), {
                center:{ lat: -1.263839, lng: 36.802224},
                zoom: 14,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false
            });

            const flightPlanCoordinates = [
                
                { lat: -1.2636811783191637, lng: 36.749415470821795},
                { lat: -1.263839, lng: 36.802224},
            ];

            const flightPath = new window.google.maps.Polyline({
                path: flightPlanCoordinates,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });

            flightPath.setMap(map);
        }
    }
}