<template>
  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <nav :class="'navbar bg-transparent p-0 pb-2'">
            <div class="container-fluid">
            
            <div class="navbar-brand bars me-2 mt-2" @click="this.$router.back()">
                <i class="ph-arrow-left"></i>
            </div>

            <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">       

            <span class="navbar-left">
                <div class="navbar-brand">
                <span style="display: -webkit-inline-box;">
                    <h4 style="margin-top: 0.2rem">
                    <i class="ph-bell-ringing text-light me-3 nav-icon circle p-2 bg-default"></i>
                    </h4>
                </span>
                </div>      
            </span>

            <div class="divider mt-4 d-none"></div>
            </div>
        </nav>
        <transition name="fade">
          <delivery :user=user v-model:screen=screen></delivery>
        </transition>        
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Delivery from '../components/Delivery/Delivery'

  export default {
    name: 'Home',
    prop_data: ['prop_screen'],
    components: {
      Delivery,
    },   
    data() {
      return {
        sidebar_data: [
          {title: 'Home', link: '', if_children: false, icon: 'home', modal: false},
          {title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false},
        ],
        stock_type: '',
        type: '',
        chosen_stock: null
      }
    },  
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
    },
    methods: {
      routeTo(value) {
        this.$router.push({name: 'Delivery' + value});
      },
      ifNotLoggedIn() {
        if( !this.user.name) {
          localStorage.remove('user')
          this.$router.push({name: 'Login'});
        } 
      }
    }    
  }
</script>